<template>
  <el-form
    :model="detail"
    status-icon
    ref="paymentForm"
    label-width="100px"
    class="company-ruleForm"
    v-loading="loading"
  >
    <el-form-item label="客户：" prop="clientId">
      <span>{{ detail.clientName }}</span>
    </el-form-item>
    <el-form-item label="收款类型：" prop="typeName">
      <span>{{ detail.typeName }}</span>
    </el-form-item>
    <el-form-item label="金额：" prop="amount">
      <span>{{ detail.amount | currency }}</span>
    </el-form-item>
    <el-form-item label="收款时间：" prop="payAt">
      <span>{{ detail.payAt | date_time }}</span>
    </el-form-item>
    <el-form-item
      label="合同号："
      prop="contractNo"
    >
      <span :class="[detail.contractNo !== null ? 'text-nav' : '']" @click="showContractDetail(detail)">{{ detail.contractNo ? detail.contractNo : "/" }}</span>
    </el-form-item>
    <el-form-item label="附件：" prop="fileIds">
      <span v-if="detail.files.length <= 0">暂无附件</span>
      <div class="file-content-list" v-if="detail.files.length > 0">
        <template v-for="(item, index) in detail.files">
          <FilesItem
          :showCheckBox="false"
            showDelete
            :item="item"
            @onDelete="onFileDelete(index)"
            :key="item.id"
            class="mt-r"
          />
        </template>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { GetPaymentsById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import FilesItem from "@/views/filesModule/components/FilesItem";
import formSelection from "../mixins/formSelection";
export default {
  components: {
    FilesItem,
  },
  mixins: [formSelection],
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      detail: {
        clientName: "",
        typeName: "",
        amount: "",
        payAt: "",
        files: [],
      },
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.loading = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    getFormData(id = "") {
      this.loading = true;
      GetPaymentsById(id)
        .then((res) => {
          this.detail = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    showContractDetail(row) {
      if(row.contractId === null){
        return false
      }
      this.$emit('close')
      this.$router.push({
        name: "ContractsDetail",
        params: {
          id: row.contractId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
</style>