<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="paymentForm"
    label-width="100px"
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
  >
    <el-form-item label="客户" prop="clientId">
       <BasicSelect v-model="form.clientId" :disabled="!isEmprty(clientId)" :allOptions="_clientOptions" />
    </el-form-item>
    <el-form-item label="收款类型" prop="typeId">
      <el-select
        v-model="form.typeId"
        placeholder="请选择"
        clearable
        filterable
        class="base-select"
      >
        <el-option
          v-for="item in paymentTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="关联合同" prop="contractId">
      <BasicSelect
        v-model="form.contractId"
        :allOptions="contractOptionFilterByClient"
      />
    </el-form-item>
    <el-form-item label="金额" prop="amount">
      <el-input
        type="number"
        v-model.trim="form.amount"
        clearable
        placeholder="请输入"
      >
        <span slot="prepend">￥</span>
      </el-input>
    </el-form-item>
    <el-form-item label="收款时间" prop="payAt">
      <el-date-picker
        style="width: 100%"
        v-model="form.payAt"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="附件" prop="fileIds">
      <el-button type="primary" size="small" @click="showFileDialog = true">{{
        fileList.length > 0 ? `已选${fileList.length}个附件` : "选择文件"
      }}</el-button>
      <div class="file-content-list" v-if="fileList.length > 0">
        <template v-for="(item, index) in fileList">
          <FilesItem
          :showCheckBox="false"
            showDelete
            :item="item"
            @onDelete="onFileDelete(index)"
            :key="item.id"
            class="mt-r"
          />
        </template>
      </div>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('paymentForm')"
        >立即创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('paymentForm')"
        v-if="!isEmprty(id)"
        >立即修改</el-button
      >
    </el-form-item>
    <el-dialog
      title="选择文件"
      :visible.sync="showFileDialog"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <FilesView
        ref="FilesView"
        :fileItems="fileList"
        @confirm="onFileConfirm"
        @cancel="onFileCancel"
      />
    </el-dialog>
  </el-form>
</template>

<script>
import { PutPaymentsById, PostPayments, GetPaymentsById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { getToken } from "@/utils/auth";
import FilesView from "@/views/filesModule/FilesView";
import FilesItem from "@/views/filesModule/components/FilesItem";
import formSelection from "../mixins/formSelection";
import BasicSelect from "@/components/BasicSelect";
export default {
  components: {
    FilesView,
    FilesItem,
    BasicSelect,
  },
  mixins: [formSelection],
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
    clientId: {
      type: [Number],
      default: null,
    },
  },
  data() {
    return {
      showFileDialog: false,
      loading: false,
      submitting: false,
      form: {
        typeId: null,
        amount: null,
        payAt: null,
        contractId: null,
        clientId: null,
        fileIds: [],
      },
      fileList: [],
      rules: {
        amount: [{ required: true, message: "请输入金额", trigger: "blur" }],
        clientId: [
          {
            required: true,
            message: "请选择客户",
            trigger: ["blur", "change"],
          },
        ],
        payAt: [
          {
            required: true,
            message: "请选择收款时间",
            trigger: ["blur", "change"],
          },
        ],
        typeId: [
          {
            required: true,
            message: "请选择收款类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      headers: {
        Authorization: getToken(),
      },
    };
  },
  computed: {
    contractOptionFilterByClient() {
      if (!isEmprty(this.form.clientId)) {
        return this.contractOptions.filter(
          (item) => item.clientId === this.form.clientId
        );
      } else {
        return this.contractOptions;
      }
    },
    _clientOptions() {
      return this.$store.state.basicOption.clientOptions;
    },
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.inintForm();
        }
        this.initFormSelection();
      },
      immediate: true,
    },
    clientId: {
      handler() {
        this.initClientParams();
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    initClientParams() {
      if (!isEmprty(this.clientId)) {
        this.form.clientId = this.clientId;
      } else {
        this.form.clientId = null;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          this.onPostForm();
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onPostForm() {
      PostPayments({
        ...this.form,
        fileIds: this.fileList.map((item) => item.id),
      })
        .then(() => {
          this.submitting = false;
          this.onEmitSuccess();
          this.inintForm();
          this.$message.success("创建成功");
        })
        .catch((err) => {
          this.submitting = false;
          ShowApiError("新增失败", err);
        });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutPaymentsById(this.id, {
            ...this.form,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.submitting = false;
              this.onEmitSuccess();
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetPaymentsById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.fileList = res.data.files;
          this.form.fileIds = res.data.files.map((item) => item.id);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onClientChange() {
      this.form.contractId = null;
    },
    onCancel() {
      this.inintForm();
      this.$emit("cancel");
    },
    onEmitSuccess() {
      this.$emit("success");
    },
    inintForm() {
      this.form = {
        typeId: null,
        amount: null,
        payAt: null,
        clientId: null,
        fileIds: [],
      };
      this.fileList = [];
      this.initClientParams();
      this.$nextTick(() => {
        this.$refs["paymentForm"].clearValidate();
      });
    },
    onFileConfirm({ items }) {
      this.fileList = items;
      this.showFileDialog = false;
    },
    onFileCancel() {
      this.showFileDialog = false;
    },
    onFileDelete(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
</style>