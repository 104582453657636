import { GetPaymentsTypesAll } from "@/views/paymentsTypes/api";
import { GetContractsAll } from "@/views/contracts/api";
import { GetClientsAll } from "@/views/client/api";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            paymentTypeOptions: [],
            clientOptions: [],
            contractOptions: []
        }
    },
    created() {
        this.initFormSelection();
    },
    methods: {
        initFormSelection() {
            this.getPaymenTypeOptions();
            this.getClientOptions();
            this.getContractsAll();
        },
        getPaymenTypeOptions() {
            GetPaymentsTypesAll()
                .then((res) => {
                    this.paymentTypeOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取收款类型选单", err);
                });
        },
        getClientOptions() {
            GetClientsAll()
                .then((res) => {
                    console.log('客户数据',this.clientOptions)
                    this.clientOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取客户类型选单", err);
                });
        },
        getContractsAll() {
            GetContractsAll()
                .then((res) => {
                    this.contractOptions = res.data.map(item => {
                        return {
                            ...item,
                            name: `${item.clientName} - ${item.contractNo}`
                        }
                    })
                })
                .catch((err) => {
                    ShowApiError("获取合同选单", err);
                });
        },

    }
}