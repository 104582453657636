import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的收款列表
export function GetPaymentsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/payments/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取收款的分页列表
export function GetPaymentsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/payments/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的收款数据
export function GetPaymentsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/payments/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定收款的数据
export function PutPaymentsById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/payments/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建收款
export function PostPayments(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/payments`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 禁用\启用指定用户
export function ToggleDisableById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/payments/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态批量设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleDisable(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.put(`/v1/payments/${id}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
 export function GetDiasbleCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/payments/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
 export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/payments/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}